import { API_BASEURL } from "../config";

export const getProjectFromApi = (username, project) => {
  return fetch(
    `${API_BASEURL}/api/project?name=${project}&username=${username}`
  )
    .then(res => {
      if (res.status !== 200) {
        throw new Error("Project Not Found");
      }
      return res.json();
    })
    .then(res => res.project);
};
